<template>
  <div
    v-if="sportTemplateLoading"
    class="sport-template__root center"
  >
    <Spinner />
  </div>
  <div
    v-else
    class="sport-template__root"
  >
    <div class="sport-template__header">
      <div class="sport-template__text">
        <Icon
          @click="backToTemplatesBySport"
          name="chevron-left"
        />
        <div
          v-if="titleInEditMode"
          ref="templateTitle"
        >
          <TextInput
            v-model="sportTemplate.name"
            no-clear-button
            small
            autofocus
            @unfocus="updateSportTemplateName"
          />
        </div>
        <Heading
          v-else
          @click="setTitleInEditMode"
        >
          {{ sportTemplate.name }}
        </Heading>
        <p>Created {{ sportTemplateCreatedAt }}</p>
      </div>
      <Button
        variant="primary"
        icon="arrow-up"
        :loading="isPropagating"
        @click="propagate"
      >
        Propagate
      </Button>
    </div>
    <div class="sport-template__content">
      <div class="included-markets sport-template__section">
        <div class="sport-template--section__header">
          <Heading
            as="h2"
            size="xs"
          >
            Included markets
          </Heading>
          <div
            v-if="includedMarkets.length"
            class="add-btn"
            @click="addNewMarkets"
          >
            <Icon :name="'plus'" />
            <span>Add</span>
          </div>
        </div>
        <div class="section-subtitle">
          {{ includedMarketsCount }}/{{ allMarketsCount }} markets included
        </div>
        <div
          v-if="!includedMarkets?.length"
          class="no-assigned-items"
        >
          <p>
            Currently, this template does not contain any markets.
            Start adding markets by clicking on button.
          </p>
          <Button
            icon="plus"
            variant="primary"
            @click="addNewMarkets"
          >
            Add markets
          </Button>
        </div>
        <IncludedMarkets
          v-else
          :markets="includedMarkets"
          :market-groups="marketGroups"
          @onConfigureMarket="configureMarket"
          @onMarketEnabledToggled="toggleMarketTemplateEnabled"
          @onRemoveMarket="toggleActiveMarketTemplate"
        />
      </div>
      <div class="assigned-competitions sport-template__section">
        <div class="sport-template--section__header">
          <Heading
            as="h2"
            size="xs"
          >
            Assigned competitions
          </Heading>
          <div
            v-if="appliedCompetitions.length"
            class="add-btn"
            @click="addNewCompetitions"
          >
            <Icon :name="'plus'" />
            <span>Add</span>
          </div>
        </div>
        <div class="section-subtitle">
          {{ appliedCompetitions.length }} competitions
        </div>
        <div
          v-if="!appliedCompetitions.length"
          class="no-assigned-items"
        >
          <p>
            Currently, this template is not assigned to any competitions or regions.
            <br> Start by clicking on button.
          </p>
          <Button
            icon="plus"
            variant="primary"
            @click="addNewCompetitions"
          >
            Add competition
          </Button>
        </div>
        <AssignedCompetitions
          v-else
          :competitions="appliedCompetitions"
          :read-only="readOnly"
          @onRemoveCompetition="toggleAssignedCompetitions"
        />
      </div>
      <div class="assigned-competitions sport-template__content-column">
        <SgpMarginConfiguration
          :sgp-margin-configuration-prop="sportTemplate.sgpMarginConfiguration || {}"
          @submit="updateSgpMarginConfiguration"
        />
        <MarketTiers
          :id="sportTemplate.sportTemplateId"
          :limit-configuration="limitConfiguration"
          @update:limit-configuration="updateLimitConfiguration"
          @saved-limit-configuration="setLimitConfiguration"
          hide-indicators
          persist
        />
        <RiskConfigurationForm
          :original-risk-configurations="originalRiskConfigurations"
          :risk-configurations="riskConfigurations"
          @update:risk-configurations="setRiskConfigurations"
          @submit="updateRiskConfigurations"
        />
      </div>
      <div class="assigned-competitions sport-template__content-column">
        <FeedPriorityForm
          v-model:sources="sportTemplate.feedSources"
          :original-sources="originalSportTemplate.feedSources"
          :options="sportTemplate.availableFeeds"
          :disabled="processingFeedSources"
          @submit="updateFeedPriorities"
        />
      </div>
    </div>
    <MarketsSelectionPrompt
      @onBulkConfigureMarket:option="bulkConfigureMarkets"
    />

    <IncludeMarketsModal
      v-if="includeMarketsModalActive"
      :is-active="includeMarketsModalActive"
      :markets="allMarkets"
      :market-groups="marketGroups"
      @close="closeMarketsModal"
      @onSave="toggleActiveMarketTemplate"
    />
    <AssigneCompetitionsModal
      v-if="assigneCompetitionsModalActive"
      :is-active="assigneCompetitionsModalActive"
      :competitions="allCompetitions"
      :read-only="readOnly"
      @close="closeCompetitionsModal"
      @onSave="toggleAssignedCompetitions"
    />
    <MarketConfigurationsPopup
      v-if="configurationPopupOpened"
      :configuration-data="configurationData"
      @closePopup="closeMarketConfigurationPopup"
    />
    <MarketConfigurationsBulkPopup
      v-if="bulkConfigurationPopupOpened"
      :sport-template-id="sportTemplate.sportTemplateId"
      :configuration-type="bulkConfigurationModalType"
      @closePopup="closeMarketConfigurationPopup"
    />
    <UnsavedChangesPopup
      v-if="unsavedChangesPopupActive"
      @onLeaveCancel="() => { unsavedChangesPopupActive = false; }"
      @onLeaveConfirm="confirmGoBack"
    />
    <div
      class="background-cover"
      v-if="configurationPopupOpened || bulkConfigurationPopupOpened"
    />
  </div>
</template>

<script>
import {
  ref, computed, onMounted, onBeforeUnmount, watch,
} from 'vue';
import { onClickOutside } from '@vueuse/core';
import {
  onBeforeRouteLeave,
  useRoute,
  useRouter,
} from 'vue-router';
import { useStore } from 'vuex';
import {
  capitalize, cloneDeep, map, filter, includes, isEqual, reduce, isEmpty, uniqBy, find, isNil, flatMap, take,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import * as api from '@/services/api';
import sportTemplateHelper from '@/services/helpers/sport-template';
import { parseLimitConfiguration } from '@/services/helpers/market-tiers';
import Heading from '@/components/common/Heading';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';
import TextInput from '@/components/common/TextInput';
import MarketTiers from '@/components/common/MarketTiers';
import IncludedMarkets from './markets/IncludedMarkets';
import IncludeMarketsModal from './markets/IncludeMarketsModal';
import MarketConfigurationsPopup from './markets/MarketConfigurationsPopup';
import MarketConfigurationsBulkPopup from './markets/MarketConfigurationsBulkPopup';
import AssignedCompetitions from './competitions/AssignedCompetitions';
import AssigneCompetitionsModal from './competitions/AssigneCompetitionsModal';
import UnsavedChangesPopup from './common/UnsavedChangesPopup';
import RiskConfigurationForm from './common/RiskConfigurationForm';
import FeedPriorityForm from './common/FeedPriorityForm';
import SgpMarginConfiguration from './common/SgpMarginConfiguration';
import MarketsSelectionPrompt from './markets/MarketsSelectionPrompt';

export default {
  components: {
    Heading,
    Button,
    Icon,
    Spinner,
    IncludedMarkets,
    IncludeMarketsModal,
    AssignedCompetitions,
    AssigneCompetitionsModal,
    MarketConfigurationsPopup,
    MarketConfigurationsBulkPopup,
    UnsavedChangesPopup,
    TextInput,
    MarketTiers,
    RiskConfigurationForm,
    FeedPriorityForm,
    SgpMarginConfiguration,
    MarketsSelectionPrompt,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const includeMarketsModalActive = ref(false);
    const assigneCompetitionsModalActive = ref(false);
    const updatedAppliedCompetitions = ref({});
    const unsavedChangesPopupActive = ref(false);
    const allowRouteChange = ref(false);

    // SPORT TEMPLATE
    const sportTemplateId = computed(() => route.params.id);
    const sportTemplate = ref(null);
    const marketGroups = ref(null);
    const originalSportTemplate = ref(null);
    const sportTemplateLoading = ref(true);
    const sportTemplateCreatedAt = computed(() => (sportTemplate.value ? format(zonedTimeToUtc(sportTemplate.value.createdAt, 'UTC'), 'MMM d, yyyy') : ''));
    const sportTemplateChanged = computed(() => !isEqual(originalSportTemplate.value, sportTemplate.value) || !isEmpty(updatedAppliedCompetitions.value));
    const saveSportTemplateProcessing = ref(false);
    const readOnly = computed(() => sportTemplate.value.default);
    const templateTitle = ref();
    const titleInEditMode = ref(false);
    const limitConfiguration = computed(() => parseLimitConfiguration(sportTemplate.value.limitconfiguration));
    const displayMarketsConfiguration = computed(() => store.getters.displayMarketsConfiguration);

    const loadSportTemplate = async (suppressLoader = false) => {
      if (!suppressLoader) sportTemplateLoading.value = true;
      const response = await api.findSportTemplateByIdAndAvailableFeeds(sportTemplateId.value);
      await store.dispatch('getMarketDisplayConfigurations', response.sportTemplate.sport.sportId);
      const paddedFeedSources = [...response.sportTemplate.feedSources, '-', '-', '-'];
      const sanitizedFeedSources = take(paddedFeedSources, 3);
      originalSportTemplate.value = {
        ...response.sportTemplate,
        feedSources: sanitizedFeedSources,
        availableFeeds: [
          {
            value: '-',
            label: 'None',
          },
          ...map(response.availableFeeds?.nodes || [], ({ feed }) => ({
            value: feed,
            label: capitalize(feed),
          })),
        ],
        marketConfigurations: sportTemplateHelper.transformMarketConfigurations(response.sportTemplate.marketConfigurations.nodes, displayMarketsConfiguration.value),
        allMarketConfigurations: sportTemplateHelper.transformMarketConfigurations(response.sportTemplate.marketConfigurations.nodes, displayMarketsConfiguration.value),
      };
      updatedAppliedCompetitions.value = {};
      sportTemplate.value = cloneDeep(originalSportTemplate.value);
      sportTemplateLoading.value = false;
    };
    const updateLimitConfiguration = (newLimitConfiguration) => {
      sportTemplate.value.limitconfiguration = newLimitConfiguration;
    };
    const setLimitConfiguration = (newLimitConfiguration) => {
      originalSportTemplate.value.limitconfiguration = newLimitConfiguration;
    };
    const marketTemplates = ref([]);
    const marketTemplatesLoading = ref(true);
    const loadMarketTemplates = async (suppressLoader = false) => {
      if (!suppressLoader) marketTemplatesLoading.value = true;
      const marketTemplatesResponse = await api.findMarketTemplates({
        filter: {
          sportId: {
            equalTo: sportTemplate.value.sport.sportId,
          },
        },
      });
      marketTemplates.value = sportTemplateHelper.filterInactiveMarketTemplates(
        sportTemplateHelper.transformMarketTemplates(marketTemplatesResponse, displayMarketsConfiguration.value),
        sportTemplate.value.marketConfigurations,
      );
      marketTemplatesLoading.value = false;
    };

    const loadMarketGroups = async (suppressLoader = false) => {
      if (!suppressLoader) marketTemplatesLoading.value = true;
      const marketGroupsResponse = await api.getMarketGroups({
        filter: {
          sportId: {
            equalTo: sportTemplate.value.sport.sportId,
          },
        },
      });
      marketGroups.value = marketGroupsResponse;
      marketTemplatesLoading.value = false;
    };

    const includedMarkets = computed(() => sportTemplateHelper.orderMarketTemplates(sportTemplate.value?.marketConfigurations || []));
    const availableMarketTemplates = computed(() => sportTemplateHelper.orderMarketTemplates(marketTemplates.value || []));
    const allMarkets = computed(() => {
      let markets = [];
      markets = [
        ...map(includedMarkets.value, (market) => ({
          ...market,
          included: true,
        })),
        ...map(availableMarketTemplates.value, (market) => ({
          ...market,
          included: false,
        })),
      ];
      return sportTemplateHelper.orderMarketTemplates(markets || []);
    });
    const allMarketsCount = computed(() => uniqBy(allMarkets.value, 'marketTemplateId').length);
    const enabledMarketsCount = computed(() => filter(uniqBy(allMarkets.value, 'marketTemplateId'), (market) => market.enabled).length);
    const includedMarketsCount = computed(() => filter(uniqBy(allMarkets.value, 'marketTemplateId'), (market) => market.included).length);
    const addNewMarkets = () => { includeMarketsModalActive.value = true; };
    const closeMarketsModal = () => { includeMarketsModalActive.value = false; };
    const addActiveMarketTemplates = (addedMarketTemplates) => {
      const originalMarketConfigurations = cloneDeep(originalSportTemplate.value.marketConfigurations);
      const originalMarketTemplates = cloneDeep(marketTemplates.value);

      const marketTemplateIds = map(addedMarketTemplates, (market) => market.marketTemplateId);
      const addedTemplates = map(
        filter(marketTemplates.value, (market) => includes(marketTemplateIds, market.marketTemplateId)),
        (template) => {
          const { enabled, ...templateWithoutEnabled } = template;
          return templateWithoutEnabled;
        },
      );

      marketTemplates.value = filter(
        marketTemplates.value,
        (marketTemplate) => !includes(marketTemplateIds, marketTemplate.marketTemplateId),
      );
      sportTemplate.value = {
        ...cloneDeep(sportTemplate.value),
        marketConfigurations: [
          ...sportTemplate.value.marketConfigurations,
          ...addedTemplates,
        ],
      };

      const sportLogicTemplateIds = flatMap(addedMarketTemplates, ({ marketTemplateId }) => {
        const templates = filter(allMarkets.value, (market) => market.marketTemplateId === marketTemplateId);
        return map(templates, 'sportLogicTemplate.sportLogicTemplateId');
      });
      api.addSportTemplateMarketConfigs({
        id: sportTemplate.value.sportTemplateId,
        payload: { sportLogicTemplateIds },
      }).then(async () => {
        const enableds = await api.findSportTemplateEnabled({
          id: sportTemplate.value.sportTemplateId,
          sportLogicTemplateIds: map(addedMarketTemplates, (template) => template.sportLogicTemplate.sportLogicTemplateId),
        });
        sportTemplate.value.marketConfigurations = map(sportTemplate.value.marketConfigurations, (config) => {
          const foundEnabledConfig = find(
            enableds.marketConfigurations.nodes,
            (enabledConfig) => config.sportLogicTemplate.sportLogicTemplateId === enabledConfig.sportLogicTemplate.sportLogicTemplateId,
          );
          return {
            ...config,
            enabled: isNil(foundEnabledConfig?.enabled) ? config.enabled : foundEnabledConfig.enabled,
          };
        });
        originalSportTemplate.value.marketConfigurations = cloneDeep(sportTemplate.value.marketConfigurations);
        store.dispatch('addNotification', {
          message: 'Market config(s) succesfully added',
          type: 'success',
          duration: 5000,
        });
      }).catch((error) => {
        console.error(error);
        sportTemplate.value.marketConfigurations = originalMarketConfigurations;
        marketTemplates.value = originalMarketTemplates;
        store.dispatch('addNotification', {
          message: 'Unable to add market config(s)',
          type: 'error',
          duration: 5000,
        });
      });
    };

    const removeActiveMarketTemplates = (addedMarketTemplates) => {
      const originalMarketConfigurations = cloneDeep(originalSportTemplate.value.marketConfigurations);
      const originalMarketTemplates = cloneDeep(marketTemplates.value);

      const marketTemplateIds = map(addedMarketTemplates, (market) => market.marketTemplateId);
      const removedTemplates = filter(
        sportTemplate.value.marketConfigurations,
        (market) => includes(marketTemplateIds, market.marketTemplateId),
      );

      sportTemplate.value = {
        ...cloneDeep(sportTemplate.value),
        marketConfigurations: filter(
          sportTemplate.value.marketConfigurations,
          (marketTemplate) => !includes(marketTemplateIds, marketTemplate.marketTemplateId),
        ),
      };
      marketTemplates.value = [
        ...marketTemplates.value,
        ...removedTemplates,
      ];

      const sportLogicTemplateIds = flatMap(addedMarketTemplates, ({ marketTemplateId }) => {
        const templates = filter(allMarkets.value, (market) => market.marketTemplateId === marketTemplateId);
        return map(templates, 'sportLogicTemplate.sportLogicTemplateId');
      });
      api.removeSportTemplateMarketConfigs({
        id: sportTemplate.value.sportTemplateId,
        payload: { sportLogicTemplateIds },
      }).then(() => {
        originalSportTemplate.value.marketConfigurations = cloneDeep(sportTemplate.value.marketConfigurations);
        store.dispatch('addNotification', {
          message: 'Market config(s) succesfully removed',
          type: 'success',
          duration: 5000,
        });
      }).catch((error) => {
        console.error(error);
        sportTemplate.value.marketConfigurations = originalMarketConfigurations;
        marketTemplates.value = originalMarketTemplates;
        store.dispatch('addNotification', {
          message: 'Unable to remove market config(s)',
          type: 'error',
          duration: 5000,
        });
      });
    };

    const toggleActiveMarketTemplate = async (changedMarketTemplates) => {
      const addedMarketTemplates = filter(changedMarketTemplates, { included: true });
      const removedMarketTemplates = filter(changedMarketTemplates, { included: false });

      if (addedMarketTemplates.length) {
        addActiveMarketTemplates(addedMarketTemplates);
      }
      if (removedMarketTemplates.length) {
        removeActiveMarketTemplates(removedMarketTemplates);
      }
    };

    const toggleMarketTemplateEnabled = ({ marketTemplateId, sportLogicTemplate: { sportLogicTemplateId } }, value) => {
      const originalMarketConfniguration = cloneDeep(sportTemplate.value.marketConfigurations);
      const updatedTemplates = reduce(
        sportTemplate.value.marketConfigurations,
        (allMarketTemplates, marketTemplate) => {
          if (marketTemplate.marketTemplateId !== marketTemplateId) return allMarketTemplates;
          return [
            ...allMarketTemplates,
            {
              ...marketTemplate,
              enabled: value,
            },
          ];
        },
        [],
      );
      const otherTemplates = filter(
        sportTemplate.value.marketConfigurations,
        (marketTemplate) => marketTemplate.marketTemplateId !== marketTemplateId,
      );
      sportTemplate.value.marketConfigurations = [
        ...otherTemplates,
        ...updatedTemplates,
      ];

      const toggleSportTemplateMarketConfig = value
        ? api.enableSportTemplateMarketConfigs
        : api.disableSportTemplateMarketConfigs;
      toggleSportTemplateMarketConfig({
        id: sportTemplate.value.sportTemplateId,
        payload: {
          sportLogicTemplateIds: [sportLogicTemplateId],
        },
      }).then(() => {
        originalSportTemplate.value.marketConfigurations = [
          ...otherTemplates,
          ...updatedTemplates,
        ];
        store.dispatch('addNotification', {
          message: 'Market config(s) succesfully updated',
          type: 'success',
          duration: 5000,
        });
      }).catch((error) => {
        console.error(error);
        sportTemplate.value.marketConfigurations = originalMarketConfniguration;
        store.dispatch('addNotification', {
          message: 'Unable to update market config(s)',
          type: 'error',
          duration: 5000,
        });
      });
    };

    onClickOutside(templateTitle, () => {
      titleInEditMode.value = false;
    });

    const setTitleInEditMode = () => {
      titleInEditMode.value = true;
    };

    // COMPETITIONS
    const competitionsLoading = ref(false);
    const allCompetitions = ref([]);
    const appliedCompetitions = computed(() => filter(allCompetitions.value, { applied: true }));
    const loadCompetitions = async (suppressLoader = false) => {
      if (!suppressLoader) marketTemplatesLoading.value = true;
      competitionsLoading.value = true;
      api.findCompetitionTemplates({
        sportId: sportTemplate.value.sport.sportId,
        sportTemplateId: sportTemplateId.value,
      })
        .then((response) => {
          allCompetitions.value = response;
        })
        .catch((error) => {
          console.log(error);
          allCompetitions.value = [];
        })
        .finally(() => {
          competitionsLoading.value = false;
          marketTemplatesLoading.value = false;
        });
    };
    const changeCompetitionsAssigned = (competitionsIds, isApplied) => {
      allCompetitions.value = map(allCompetitions.value, (competition) => {
        if (includes(competitionsIds, competition.competitionId)) {
          updatedAppliedCompetitions.value[competition.competitionId] = isApplied;
          return {
            ...competition,
            applied: isApplied,
          };
        }
        return competition;
      });
    };
    const toggleAssignedCompetitions = (changedCompetitions) => {
      const allCompetitionsCopy = cloneDeep(allCompetitions.value);
      const addedCompetititons = filter(changedCompetitions, { applied: true });
      const addedCompetititonsIds = map(addedCompetititons, (c) => c.competitionId);
      const removedCompetitions = filter(changedCompetitions, { applied: false });
      const removedCompetititonsIds = map(removedCompetitions, (c) => c.competitionId);
      if (addedCompetititons.length) {
        changeCompetitionsAssigned(addedCompetititonsIds, true);
      }
      if (removedCompetitions.length) {
        changeCompetitionsAssigned(removedCompetititonsIds, false);
      }
      api.updateSportTemplateAssignedCompetitions({
        sportTemplateId: sportTemplateId.value,
        idsToAssign: addedCompetititonsIds,
        idsToUnassign: removedCompetititonsIds,
      }).catch((error) => {
        console.error(error);
        allCompetitions.value = allCompetitionsCopy;
      }).finally(() => {
        updatedAppliedCompetitions.value = [];
      });
    };
    const addNewCompetitions = () => { assigneCompetitionsModalActive.value = true; };
    const closeCompetitionsModal = () => { assigneCompetitionsModalActive.value = false; };

    // CONFIGURATION POPUP
    const configurationPopupOpened = ref(false);
    const configurationData = ref(null);

    const configureMarket = (marketTemplate) => {
      const configurationRulesets = filter(includedMarkets.value, { marketTemplateId: marketTemplate.marketTemplateId });
      configurationData.value = {
        name: marketTemplate.name,
        createdAt: originalSportTemplate.value.createdAt,
        updatedAt: originalSportTemplate.value.updatedAt,
        configurationRulesets,
        sportId: originalSportTemplate.value.sport.sportId,
        sportTemplateId: originalSportTemplate.value.sportTemplateId,
        sportTemplateName: originalSportTemplate.value.name,
        isDefault: originalSportTemplate.value.default,
      };
      configurationPopupOpened.value = true;
    };

    // BULK CONFIGURATION POPUP
    const bulkConfigurationPopupOpened = ref(false);
    const bulkConfigurationModalType = ref('');
    const bulkConfigureMarkets = (option) => {
      bulkConfigurationModalType.value = option;
      bulkConfigurationPopupOpened.value = true;
    };

    // SHARED ACTIONS
    const load = async (suppressLoader = false) => {
      try {
        await loadSportTemplate(suppressLoader);
        if (!suppressLoader) sportTemplateLoading.value = true;
        await Promise.all([
          loadMarketTemplates(suppressLoader),
          loadMarketGroups(suppressLoader),
          loadCompetitions(),
        ]);
        if (!marketGroups.value.length) {
          marketGroups.value = [{
            marketCode: 'ALL',
            displayName: 'All',
            markets: { nodes: allMarkets.value },
          }];
        }
      } finally {
        sportTemplateLoading.value = false;
      }
    };
    const closeMarketConfigurationPopup = (reload) => {
      configurationPopupOpened.value = false;
      bulkConfigurationPopupOpened.value = false;
      configurationData.value = null;
      if (reload) load();
    };
    const saveSportTemplate = () => {
      saveSportTemplateProcessing.value = true;
      api.updateSportTemplate(
        sportTemplate.value.sportTemplateId,
        sportTemplateHelper.createSportTemplateRequestPayload(sportTemplate.value),
      )
        .then(() => {
          const competitionsPayload = reduce(
            updatedAppliedCompetitions.value,
            (actualRequestBody, applied, competitionId) => {
              const groupName = (applied ? 'toSet' : 'toUnset');
              return {
                ...actualRequestBody,
                [groupName]: [
                  ...actualRequestBody[groupName],
                  competitionId,
                ],
              };
            },
            {
              toSet: [],
              toUnset: [],
            },
          );
          return api.updateSportTemplateCompetitions({
            sportTemplateId: sportTemplate.value.sportTemplateId,
            payload: competitionsPayload,
          });
        })
        .then(() => {
          store.dispatch('addNotification', {
            message: 'Market templates succesfully saved',
            type: 'success',
            duration: 5000,
          });
          load(true);
        })
        .catch(() => {
          store.dispatch('addNotification', {
            message: 'Unable to update template',
            type: 'error',
            duration: 5000,
          });
        })
        .finally(() => {
          saveSportTemplateProcessing.value = false;
        });
    };
    const backToTemplatesBySport = () => {
      router.replace({
        name: 'market-templates',
        query: {
          sport: sportTemplate.value.sport.sportId,
        },
      });
    };
    const confirmGoBack = () => {
      allowRouteChange.value = true;
      backToTemplatesBySport();
    };
    const preventPageLeaveIfEditing = (pageReload) => {
      if (!sportTemplateChanged.value) return false;
      if (pageReload) {
        return 'You have unsaved changes, do you want to continue?';
      }
      unsavedChangesPopupActive.value = true;
      return true;
    };
    onMounted(() => {
      load();
    });
    onBeforeRouteLeave(() => {
      if (preventPageLeaveIfEditing() && !allowRouteChange.value) { return false; }
      return true;
    });
    onBeforeUnmount(() => {
      store.dispatch('saveMarketTemplateMarketsScrollPosition', 0);
      window.onbeforeunload = null;
    });
    watch(
      () => sportTemplateChanged.value,
      (newValue) => {
        if (newValue) {
          window.onbeforeunload = () => preventPageLeaveIfEditing(true);
        } else {
          window.onbeforeunload = null;
        }
      },
    );

    const updateSportTemplateName = () => {
      const oldName = originalSportTemplate.value.name;
      const newName = sportTemplate.value.name.trim();

      if (!newName?.length || newName === oldName) {
        sportTemplate.value.name = oldName;
        return;
      }

      api.updateSportTemplateName({
        id: sportTemplate.value.sportTemplateId,
        name: newName,
      }).then(() => {
        sportTemplate.value.name = newName;
        originalSportTemplate.value.name = newName;
        store.dispatch('addNotification', {
          message: 'Sport template name succesfully changed',
          type: 'success',
          duration: 5000,
        });
      }).catch((error) => {
        console.error(error);
        sportTemplate.value.name = oldName;
        store.dispatch('addNotification', {
          message: 'Unable to change sport template name',
          type: 'error',
          duration: 5000,
        });
      });
    };

    const originalRiskConfigurations = computed(() => originalSportTemplate?.value?.riskConfiguration?.nodes || []);
    const riskConfigurations = computed(() => sportTemplate?.value?.riskConfiguration?.nodes || []);
    const setRiskConfigurations = (newRiskConfigurations) => {
      sportTemplate.value = {
        ...sportTemplate.value,
        riskConfiguration: {
          ...sportTemplate.value.riskConfiguration,
          nodes: newRiskConfigurations,
        },
      };
    };
    const updateRiskConfigurations = () => {
      const oldOriginalSportTemplate = cloneDeep(originalSportTemplate.value);
      originalSportTemplate.value = cloneDeep(sportTemplate.value);
      api.updateSportTemplateGameRisk({
        id: sportTemplate.value.sportTemplateId,
        riskConfigurations: riskConfigurations.value,
      })
        .catch((error) => {
          console.error(error);
          originalSportTemplate.value = oldOriginalSportTemplate;
          sportTemplate.value = cloneDeep(oldOriginalSportTemplate);
        });
    };

    const updateSgpMarginConfiguration = (sgpConfiguration) => {
      const oldOriginalSportTemplate = cloneDeep(originalSportTemplate.value);
      originalSportTemplate.value = cloneDeep(sportTemplate.value);
      api.updateSportTemplateSgpConfiguration({
        id: sportTemplate.value.sportTemplateId,
        sgpMarginConfiguration: sgpConfiguration,
      }).then(() => {
        sportTemplate.value = {
          ...sportTemplate.value,
          sgpMarginConfiguration: sgpConfiguration,
        };
        store.dispatch('addNotification', {
          message: 'Successfully updated sgp hold application for this sport template.',
          type: 'success',
          duration: 5000,
        });
      })
        .catch((error) => {
          console.error(error);
          originalSportTemplate.value = oldOriginalSportTemplate;
          sportTemplate.value = cloneDeep(oldOriginalSportTemplate);
          store.dispatch('addNotification', {
            message: 'Couldn\'t update sgp hold for this sport template. Please try again later!',
            type: 'error',
            duration: 5000,
          });
        });
    };

    const processingFeedSources = ref(false);
    const updateFeedPriorities = (newFeedSources) => {
      processingFeedSources.value = true;
      sportTemplate.value.feedSources = newFeedSources;
      api.setSportTemplateFeedSources({
        id: sportTemplate.value.sportTemplateId,
        feedSources: filter(newFeedSources, (source) => source !== '-'),
      })
        .then(() => {
          originalSportTemplate.value.feedSources = newFeedSources;
          store.dispatch('addNotification', {
            message: 'Successfully updated primary feed sources for this sport template.',
            type: 'success',
            duration: 5000,
          });
        })
        .catch((error) => {
          console.error(error);
          sportTemplate.value.feedSources = [...originalSportTemplate.value.feedSources];
          store.dispatch('addNotification', {
            message: 'Couldn\'t update primary feed sources for this sport template. Please try again later!',
            type: 'error',
            duration: 5000,
          });
        })
        .finally(() => {
          processingFeedSources.value = false;
        });
    };

    const isPropagating = ref(false);
    const propagate = () => {
      isPropagating.value = true;
      api.propagateSportTemplate(sportTemplate.value.sportTemplateId)
        .then(() => {
          store.dispatch('addNotification', {
            message: 'Successfully propagated sport template!',
            type: 'success',
            duration: 5000,
          });
        })
        .catch((error) => {
          console.error(error);
          store.dispatch('addNotification', {
            message: 'Error occurred while propagating sport template. Please try again later.',
            type: 'error',
            duration: 5000,
          });
        })
        .finally(() => {
          isPropagating.value = false;
        });
    };

    return {
      originalSportTemplate,
      sportTemplate,
      templateTitle,
      titleInEditMode,
      setTitleInEditMode,
      sportTemplateCreatedAt,
      sportTemplateChanged,
      sportTemplateLoading,
      saveSportTemplateProcessing,
      saveSportTemplate,
      includedMarkets,
      availableMarketTemplates,
      allMarkets,
      marketGroups,
      allMarketsCount,
      enabledMarketsCount,
      allCompetitions,
      appliedCompetitions,
      includeMarketsModalActive,
      assigneCompetitionsModalActive,
      addNewMarkets,
      addNewCompetitions,
      closeMarketsModal,
      closeCompetitionsModal,
      toggleActiveMarketTemplate,
      toggleMarketTemplateEnabled,
      toggleAssignedCompetitions,
      backToTemplatesBySport,
      readOnly,
      load,
      configurationPopupOpened,
      configurationData,
      configureMarket,
      closeMarketConfigurationPopup,
      unsavedChangesPopupActive,
      confirmGoBack,
      limitConfiguration,
      updateLimitConfiguration,
      setLimitConfiguration,
      updateSportTemplateName,
      originalRiskConfigurations,
      riskConfigurations,
      setRiskConfigurations,
      updateRiskConfigurations,
      processingFeedSources,
      updateFeedPriorities,
      isPropagating,
      propagate,
      updateSgpMarginConfiguration,
      includedMarketsCount,
      bulkConfigureMarkets,
      bulkConfigurationPopupOpened,
      bulkConfigurationModalType,
    };
  },
};
</script>
<style lang="scss">
.sport-template__root {
    display: block;

    .sport-template__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    padding: 40px 16px 16px;

    .sport-template__text {
        display: grid;
        align-items: center;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        flex-direction: column;
        gap: 4px;

        .icon {
          grid-row: 1 / 3;
          width: 24px;
          height: 24px;
          padding: 4px;
          cursor: pointer;

          svg path {
            stroke: $black;
          }
        }

        p {
          font-size: 12px;
          color: $gray700;
        }
    }
    .icon svg path{
        fill: #fff;
    }
    }

    .sport-template__content {
      display: grid;
      grid-template-columns: minmax(340px, 100%) minmax(300px, 384px) minmax(340px, 384px) minmax(300px, 384px);
      grid-template-rows: 1fr;
      width: 100%;
      height: calc(100% - 100px);
      gap: 16px;
      padding: 0 16px 16px;

      .sport-template__content-column {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .sport-template__content-row {
        grid-column: 1 / 3;
        display: flex;
        flex-direction: column;
      }
    }

    &.center {
        justify-content: center;
        align-items: center;
    }

    .included-markets {
    }
    .assigned-competitions {
    }

    .sport-template__section {
        background-color: #fff;
        padding: 16px;
        border: 1px solid #DDD;
        border-radius: 4px;
        min-height: 325px;
        height: 100%;
        width: 100%;
        overflow: hidden;

        .sport-template--section__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .add-btn {
                display: flex;
                align-items: center;
                cursor: pointer;

                .icon {
                    margin-right: 6px;
                }
            }
        }

        .section-subtitle {
            font-size: 12px;
            color: $gray700;
        }

        .no-assigned-items {
            padding: 40px 0;
            text-align: center;

            p {
                max-width: 350px;
                margin: 0 auto;
                color: #A9A9A9;
            }

            .button {
                margin-top: 28px;

                .icon svg path {
                    stroke: #fff;
                }
            }
        }
    }
    .background-cover {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: rgba(#000, 0.5);
        z-index: $modalBackgroundZIndex;
        left: 0;
        top: 0;
    }

    .market-tiers {
      margin-bottom: 0;
    }
}
</style>
